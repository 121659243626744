import React from 'react'
import { PageProps } from 'gatsby'

import SEO from '../components/Seo'
import Link from '../components/Link'
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import AlquilarForm from '../components/AlquilarForm2'
import HeaderBox from '../components/HeaderBox'
import PriceList from '../components/PriceList'
import PriceList2 from '../components/PriceList2'
import PriceList3 from '../components/PriceList3'

import useGlobalState from '../hooks/useGlobalState'

const AlquilarPage: React.FC<PageProps> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { pdfUrl } = useGlobalState()

  return (
    <>
      <SEO title="Renovar" />
      <HeaderBox />
      <Flex
        direction="column"
        wrap="nowrap"
        maxW={['100%', '960px', '960px']}
        py="1rem"
        mx="auto"
      >
        {pdfUrl ? (
          <Box textAlign="center">
            <Heading textStyle="sectionTitle">¡Gracias!</Heading>
            <Text px="2rem">
              Tu solicitud fue enviada con éxito. Te contactaremos en breve mediante
              e-mail o por vía telefónica. Podes revisar tu solicitud en el correo
              electrónico que te enviamos o en el contrato debajo. Si tenés alguna duda o
              corrección que hacer, podes hacerlo desde las opciones que brindamos en la
              página de{' '}
              <Link color="primary" to="/contacto">
                contacto
              </Link>
              .
            </Text>

            <Link
              display="inline-block"
              layerStyle="cta"
              fontWeight="bold"
              textTransform="uppercase"
              my="2rem"
              to={pdfUrl}
              target="_blank"
            >
              Ver contrato
            </Link>
          </Box>
        ) : (
          <>
            <Heading textStyle="sectionTitle">Renovar una unidad</Heading>

            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent
                minW={['calc(100% - 2rem)', 'calc(100% - 2rem)', '960px']}
                mx="1rem"
                color="primary"
              >
                <ModalHeader>Precios</ModalHeader>
                <ModalCloseButton />
                <ModalBody h="fit-content">
                <Text
                textAlign="center"
                fontSize="1.5rem"
                fontWeight="bold"
                marginBottom="1.5rem">
                  Tamaño pequeño: 8.3m² (1.66x5m)</Text>
                  <PriceList />
                  <Text
                  textAlign="center"
                  fontSize="1.5rem"
                  fontWeight="bold"
                  marginBottom="1rem">
                    Tamaño mediano: 13.75m² (2.75x5m)</Text>
                  <PriceList2/>
                  <Text
                  textAlign="center"
                  fontSize="1.5rem"
                  fontWeight="bold"
                  marginBottom="1.5rem">
                    Tamaño grande: 20.5m² (4.10x5m)</Text>
                  <PriceList3/>
                
                </ModalBody>

                <ModalFooter>
                  <Button variant="ghost" mr={3} onClick={onClose}>
                    Cerrar
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            <Text fontSize="lg" px="2rem" mb="2rem">
              Para solicitar la renovación de una unidad, completá el siguiente el
              fomulario. Si tenes dudas, podes{' '}
              <Link color="primary" to="/contacto">
                enviarnos un mensaje
              </Link>{' '}
              o explorar{' '}
              <Link color="primary" to="/faq">
                las preguntas frecuentes
              </Link>
              .
            </Text>
            <Text
              fontSize="lg"
              px="2rem"
              mb="1rem"
              textAlign="center"
              textTransform="uppercase"
              fontWeight="bold"
            >
              <Link
                to="/precios"
                color="primary"
                onClick={(e) => {
                  e.preventDefault()
                  onOpen()
                }}
              >
                Ver los precios vigentes
              </Link>
            </Text>

            <Divider color="rgba(0, 0, 0, 0.2)" m="2rem" w="calc(100% - 4rem)" />

            <AlquilarForm />
          </>
        )}
      </Flex>
    </>
  )
}

export default AlquilarPage
